<template>
  <el-dialog
    top="5vh"
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    custom-class="dialog-warpper"
  >
    <el-form
      class="form"
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      v-loading="videoLoading"
      element-loading-text="视频上传中"
    >
      <el-card>
        <div slot="header">课件信息</div>

        <el-descriptions :column="2" border>
          <el-descriptions-item :labelStyle="{ width: '130px' }">
            <template slot="label">
              <span class="requireSyb">*</span>
              <span>课件标题</span>
            </template>

            <el-form-item prop="title">
              <el-input
                v-model="dataForm.title"
                placeholder="课件标题"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item :labelStyle="{ width: '130px' }">
            <template slot="label">
              <span class="requireSyb">*</span>
              <span>学习的月份</span>
            </template>

            <el-form-item prop="studyDate">
              <el-date-picker
                v-model="dataForm.studyDate"
                value-format="yyyy-MM"
                style="width: 100%"
                type="month"
                placeholder="选择月份"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <span class="requireSyb">*</span>
              <span>需学习时长(分)</span>
            </template>

            <el-form-item prop="studyDuration">
              <el-input
                v-model="dataForm.studyDuration"
                placeholder="需学习时长(分)"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <span class="requireSyb">*</span>
              <span>间隔多少分拍照</span>
            </template>

            <el-form-item prop="picInterval">
              <el-input
                v-model="dataForm.picInterval"
                placeholder="间隔多少分拍照"
                clearable
              ></el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <span class="requireSyb">*</span>
              <span>知识类型</span>
            </template>

            <el-form-item prop="knowledgeType">
              <el-select
                v-model="dataForm.knowledgeType"
                placeholder="选择知识类型"
                clearable
              >
                <el-option
                  v-for="item in knowledgeTypeOpt"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card style="margin-top: 10px">
        <div slot="header">图文内容</div>

        <el-form-item prop="content">
          <div>
            <editor ref="editor" v-model="dataForm.content"></editor>
          </div>
        </el-form-item>
      </el-card>

      <el-card style="margin-top: 10px">
        <div slot="header">课件视频</div>

        <el-form-item prop="videoUrl">
          <el-upload
            class="upload-demo"
            drag
            :action="$http.common.uploadVideo"
            :headers="{ token: $store.state.token }"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :file-list="vedioFile"
          >
            <i v-if="!vedio" class="el-icon-upload"></i>
            <video
              v-if="vedio"
              style="width: 100%"
              :src="vedio"
              controls
            ></video>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
      </el-card>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor.vue";

// mixin混入
import { priMixin } from "@/mixins/mixins.js";

export default {
  components: {
    Editor,
  },
  mixins: [priMixin],
  data() {
    return {
      videoLoading: false,
      vedio: "",
      vedioFile: [],
      visible: false,
      dataForm: {
        id: 0,
        uid: "",
        title: "",
        content: "",
        studyDuration: 20,
        picInterval: 5,
        studyDate: "",
        videoUrl: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
        knowledgeType: "",
      },
      // 表单规则
      dataRule: {
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        studyDate: [
          { required: true, message: "学习的月份不能为空", trigger: "blur" },
        ],
        studyDuration: [
          {
            required: true,
            message: "需学习时长不能为空",
            trigger: "blur",
          },
        ],
        picInterval: [
          {
            required: true,
            message: "间隔多少分拍照不能为空",
            trigger: "blur",
          },
        ],
        knowledgeType: [
          {
            required: true,
            message: "知识类型不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 获取视频接口
    getVideo() {
      if (!this.dataForm.videoUrl) return;
      this.$http.common
        .getVideoUrl({ videoId: this.dataForm.videoUrl })
        .then((res) => {
          this.videoLoading = false;
          if (res.code != 0) return this.$message.error(res.msg);
          this.vedio = res.data;
          console.log(456);
        });
    },

    // 上传视频
    handleChange(file) {
      if (!this.videoLoading) {
        this.videoLoading = true;
      }
      let new_file = { ...file };
      new_file.name = "课件视频.map4";
      this.vedioFile = [new_file];
      console.log(file);
      if (file.response) {
        // 判断视频是否上传成功
        if (file.response.code != 0)
          return this.$message({
            message: "警告哦，这是一条警告消息",
            type: "warning",
          });

        this.dataForm.videoUrl = file.response.data;

        this.getVideo();
      }
    },
    // 删除视频
    handleRemove() {
      this.vedioFile = [];
      this.dataForm.videoUrl = "";
      this.vedio = "";
    },
    init(id) {
      this.vedio = "";

      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.courseware.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              this.dataForm.content = this.dataForm.content.replace(
                /www.jddgx.online/g,
                "bh.gxyat.com"
              );
              this.dataForm.studyDuration = this.dataForm.studyDuration / 60;
              this.$refs.editor.destroy();
              this.$refs.editor.init(this.dataForm.content);

              this.getVideo();
            }
          });
        } else {
          this.$refs.editor.destroy();
          this.$refs.editor.init(this.dataForm.content);
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;
          requestDate.studyDuration = requestDate.studyDuration * 60;

          if (this.dataForm.id) {
            // 修改
            this.$http.courseware.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.courseware.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        } else {
          this.$message.error("请完善信息");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  :deep() {
    .el-form-item__content {
      z-index: 1;
    }
  }
}
</style>
