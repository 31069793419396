<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="dataForm.studyDate"
          value-format="yyyy-MM"
          type="month"
          placeholder="按月份筛选"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column align="center" label="序号" type="index" width="70">
      </el-table-column>
      <el-table-column
        prop="userName"
        align="center"
        label="发布者"
        width="160"
      >
      </el-table-column>
      <el-table-column prop="title" align="center" label="标题" min-width="200">
      </el-table-column>
      <el-table-column
        prop="knowledgeType"
        header-align="center"
        align="center"
        label="知识类型"
        width="90"
      >
        <template slot-scope="scope">
          <span>{{
            knowledgeTypeOpt.find(
              (ele) => ele.val + "" === scope.row.knowledgeType
            )?.name || ""
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="studyDuration"
        align="center"
        label="学习时长"
        width="100"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.studyDuration / 60 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="studyDate"
        align="center"
        label="培训月份"
        width="90"
      >
      </el-table-column>

      <el-table-column fixed="right" align="center" width="210" label="操作">
        <template slot-scope="scope">
          <el-button
            type="success"
            plain
            size="mini"
            @click="lookInfo(scope.row.id)"
            >查看</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id)"
            plain
            >修改</el-button
          >
          <el-button
            type="danger"
            size="mini"
            plain
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>

    <!--查看 -->
    <look-info v-if="lookInfoVisible" ref="lookInfo"></look-info>
  </div>
</template>

<script>
import AddOrUpdate from "./courseware-add-or-update";
import LookInfo from "./look-info";

import { priMixin } from "@/mixins/mixins.js";

export default {
  mixins: [priMixin],
  data() {
    return {
      lookInfoVisible: false,
      dataForm: {
        keyword: "",
        studyDate: "",
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
    LookInfo,
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 查看
    lookInfo(id) {
      this.lookInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.lookInfo.init(id);
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.courseware.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      // 全局mixin混入事件；val输入的备注(then：确认按钮后的操作；catch：取消后的操作)
      this.remarksDelete()
        .then((val) => {
          this.$http.courseware
            .delete({
              id,
              remarks: val,
            })
            .then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: data.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.mod-config {
  padding: 20px;
}
</style>