<template>
  <el-dialog
    top="5vh"
    title="课件详情"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <div
      v-html="dataForm.title"
      style="text-align: center; font-size: 30px; border-bottom: solid 1px #ddd"
    ></div>
    <div v-html="dataForm.content"></div>
    <div v-if="videoSrc">
      <video :src="videoSrc" controls width="600px"></video>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      videoSrc: "",
      dataForm: {
        id: 0,
        uid: "",
        cid: "",
        title: "",
        content: "",
        studyDuration: 20,
        picInterval: 5,
        studyDate: "",
        videoUrl: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
      },
    };
  },
  methods: {
    // 获取视频接口
    getVideo() {
      this.$http.common
        .getVideoUrl({ videoId: this.dataForm.videoUrl })
        .then((res) => {
          if (res.code != 0) return this.$message.error(res.msg);
          this.videoSrc = res.data;
        });
    },
    init(id) {
      this.dataForm.id = id || 0;
      this.visible = true;
      this.videoSrc = "";
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.$http.courseware.info({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;
              this.dataForm.content = this.dataForm.content.replace(
                /www.jddgx.online/g,
                "bh.gxyat.com"
              );
              if (this.dataForm.videoUrl) {
                this.getVideo();
              }
            }
          });
        }
      });
    },
  },
};
</script>
